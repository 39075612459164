<template>
  <div class="login">
    <div class="overlay"></div>
    <div class="login-container">
      <h2>登录</h2>
      <div class="input-group">
        <input type="text" v-model="username" placeholder="请输入您的账号" />
        <input type="password" v-model="password" placeholder="请输入您的密码" />
        <button @click="login">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref } from 'vue';

export default {
  name: 'UserLogin',
  setup() {
    const username = ref('');
    const password = ref('');
    const toast = useToast();
    const store = useStore();
    const router = useRouter();

    const login = async () => {
      if (!username.value || !password.value) {
        toast.error('账号密码不得为空');
        return;
      }

      const isChinese = /[\u4e00-\u9fa5]/;

      if (isChinese.test(username.value) || isChinese.test(password.value)) {
        toast.error('账号密码不得为中文');
        return;
      }

      if (username.value.length < 6 || password.value.length < 6) {
        toast.error('账号密码必须大于等于6位');
        return;
      }

      try {
        const response = await axios.post('/index.php?action=login', {
          username: username.value,
          password: password.value
        });

        if (response.data.code === 200) {
          localStorage.setItem('user-token', response.data.token);
          store.dispatch('login', {
            username: response.data.user.username
          });
          toast.success(response.data.message);
          router.push({ name: 'HomePage' }).then(() => {
            // 确保路由跳转成功后，重新加载页面
            window.location.reload();
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("登录失败，请稍后再试！");
      }
    };

    return {
      username,
      password,
      login
    };
  }
};
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
}

.login {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  background: 
    linear-gradient(114deg, #1F2937 0%, rgba(0, 0, 0, 0.14) 100%),
    black;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  width: 20rem;
  background-color: white;
  border-radius: 15px;
  padding: 2.78rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  align-self: flex-start;
  font-weight: normal;
  font-size: 1.78rem;
  margin-bottom: 2rem;
}

.input-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  height: 3rem;
  outline: none;
  border: 0;
  border-radius: 10px;
  color: #898989;
  background-color: #e9e9e9;
  text-align: center;
}

button {
  margin-top: 2rem;
  width: 100%;
  width: 50%;
  outline: none;
  border: 0;
  height: 3rem;
  background: linear-gradient(128deg, #1F2937 0%, rgba(24, 150, 224, 0.32) 100%);
  color: #fff;
  border-radius: 10px;
}
</style>
